import React from 'react'

import Layout from '../components/layout'

const StatuePage = () => (
  <Layout>
    <h1 className="mt-5">Satzung des Marburger Konventes studentischer Verbindungen (MK)</h1>

    <h2 className="mt-5">1 Name, Mitglieder, Grundsätze der Mitgliedschaft</h2>

    <p>§1.1 Der Verband führt den Namen „Marburger Konvent studentischer Verbindungen (MK)“. Seine
    Gründung wurde am 06.11.1971 in Marburg beschlossen.</p>

    <p>§1.2 Mitglieder des Verbandes sind studentische Verbindungen mit Ihren Aktivitates und Alt-Herren-Verbänden (nachstehend Bünde genannt), die sich zu folgenden Grundsätzen bekennen:</p>
    <ol>
      <li>Lebensbindung an den Bund (Lebensbundgrundsatz).</li>
      <li>demokratische Ordnung des Bundeslebens (Conventsgrundsatz).</li>
      <li>Förderung der Haltung und Einsatzbereitschaft der Einzelmitglieder im Bund, in der Hochschule, im Beruf und in der Gesellschaft (Persönlichkeitsgrundsatz).</li>
      <li>Bekennen zu den verfassungsmäßigen Grundrechten, sowie des demokratischen und sozialen Rechtsstaates.</li>
      <li>Tragen von Verbindungsfarben als Ausdruck der inneren und äußeren Zusammengehörigkeit (Farbengrundsatz).</li>
      <li>Pflege von Leibesübungen (Sportgrundsatz).</li>
    </ol>

    <p>§1.3 Jeder Bund gestaltet unter Beachtung von §1.2 sein Bundesleben in Form und Inhalt selbst.</p>

    <h2 className="mt-5">2 Aufgaben des Verbandes</h2>
    <p>§2.1 Aufgaben</p>
    <ol>
      <li>Der Verband hat die Aufgaben den Mitgliedsbünden zu helfen, die Grundsätze des §1.2 zu verwirklichen,</li>
      <li>den freundschaftlichen Zusammenhalt seiner Mitgliedsbünde und zwischen deren Mitgliedern zu pflegen,</li>
      <li>die gemeinsamen Belange seiner Mitgliedsbünde nach Außen zu vertreten</li>
      <li>und sich zusammen mit anderen Verbänden für ein erneuertes Verbindungsleben auch in einer sich wandelnden Gesellschaft einzusetzen.</li>
    </ol>

    <h2 className="mt-5">3 Pflichten der Mitglieder</h2>
    <p>§3.1 Die Mitglieder haben die Pflichten,</p>

    <ol>
      <li>ihren Namen mit dem Zusatz "MK" zu führen,</li>
      <li>ihr Verbindungsleben nach dieser Satzung zu gestalten,</li>
      <li>die Arbeit des Verbandes durch tätige und ordnungsgemäße Mitwirkung zu unterstützen,</li>
      <li>ihren aktiven Mitgliedern mindestens einen Sporttermin pro Woche anzubieten und alle Sportveranstaltungen des Verbandes für diese zur Pflicht zu machen.</li>
    </ol>

    <p>§3.2 Mitgliedsbeiträge</p>

    <ol>
      <li>Die Mitglieder haben dem Verband einen Beitrag zu leisten, getrennt nach Mitgliedern der Aktivitates und der Alt-Herren-Verbände. Dem MK-Kassenwart soll hierzu eine Einzugsermächtigung erteilt werden, sodass dieser die Beiträge jährlich Anfang Dezember einziehen kann. Auf der Verbandsversammlung ist auf Antrag über den Beitrag jeweils für Aktivitas und Alt-Herren-Verband zu beschließen.</li>
      <li>Einzelmitglieder, die mehreren Bünden des Verbandes angehören, werden für die Berechnung des Beitrages nur bei ihrem Stammbund gezählt.</li>
      <li>Solange ein Bund mit der Zahlung des Beitrages, eines Säumniszuschlages, einer Geldbuße oder einer Umlage über eine gesetzte Nachfrist hinaus im Rückstand ist, ruht sein Stimmrecht.</li>
    </ol>

    <h2 className="mt-5">4 Erwerb und Verlust der Mitgliedschaft, Suspendierung</h2>
    <p>§4.1 Mitgliedschaft</p>

    <ol>
      <li>Für die Mitgliedschaft im Verband, ist die Anerkennung dieser Satzung Voraussetzung.</li>
      <li>Die Aufnahmeanträge sind beim Präsidierenden Bund zu stellen. Die Aufnahme erfolgt durch die Verbandsversammlung oder im schriftlichen Verfahren nach §5.6.</li>
    </ol>


    <p>§4.2 Beendigung der Mitgliedschaft</p>
    <ol>
      <li>Die Mitgliedschaft endet durch
          <p className='mb-1'>a) Austritt oder</p>
          <p className='mb-1'>b) Ausschluss.</p>
      </li>
      <li>Der Austritt ist durch einen eingeschriebenen Brief an den präsidierenden Bund mit der Frist von 6 Monaten zum Schluss des Geschäftsjahres zulässig.</li>
      <li>Der Ausschluss ist zulässig, wenn ein Mitgliedsbund seine Verbandspflichten grob verletzt oder das Ansehen des Verbandes erheblich gefährdet. Nach Untersuchungen durch den Präsidierenden Bund oder die von ihm Beauftragten entscheidet auf Antrag des Präsidierenden Bundes die Verbandsversammlung.</li>
      <li>Die Beitragspflichten eines ausgeschlossenen Bundes enden mit dem Schluss des Geschäftsjahres.</li>
    </ol>

    <p>§4.3 Suspendierung</p>
    <ol>
      <li>Ein Mitgliedsbund, der seine Aktivitas auf Zeit (Suspendierung) oder endgültig  aufgibt, gehört durch seinen Alt-Herren-Verband weiterhin dem Verband an.</li>
    </ol>


    <h2 className="mt-5">5 Organe und Organfunktionen des Verbandes</h2>

    <p>§5.1 Die Organe des MK sind</p>
    <ol>
      <li>die Verbandsversammlung (VV)</li>
      <li>der Präsidierende Bund</li>
      <li>der Ständige Ausschuss (STA) </li>
      <li>das Kassenamt </li>
      <li>der Alt-Herren-Rat (AH-Rat) </li>
    </ol>

    <p>§5.2 Die Verbandsversammlung</p>
    <ol>
      <li>Die Verbandsversammlung ist das oberste Organ.</li>
      <li>Sie findet jährlich einmal statt. Zeit und Ort werden auf der ordentlichen Versammlung für die nächste festgelegt.</li>
      <li>Der Präsidierende Bund lädt die Aktivitates und Alt-Herren-Verbände unter Mitteilung der Tagesordnung und der eingegangenen Anträge nebst deren Begründungen mit einer Frist von vier Wochen zur VV ein.</li>
      <li>Der Präsidierende Bund kann im Einvernehmen mit dem STA eine außerordentliche Verbandsversammlung einberufen und muss dies auf Antrag von mindestens einem Drittel der den Bünden zustehenden Stimmen tun.</li>
      <li>Wird eine Satzungsänderung in erster Lesung auf einer außerordentlichen VV behandelt, muss der Antrag 6 Wochen vorher schriftlich an alle Mitgliedsbünde übermittelt werden.</li>
    </ol>

    <p>§5.3 Kompetenzen der Verbandsversammlung</p>
    <ol>
      <li>Die Verbandsversammlung beschließt in allen ihr in dieser Satzung oder in der Geschäftsordnung zugewiesenen Angelegenheiten.</li>
      <li>Die Verbandsversammlung beschließt ferner über Verwaltungsfragen von entsprechender Bedeutung, insbesondere über die jährliche Festsetzung des Haushaltsplanes, nach dem Bericht der Kassenprüfer auch über die Entlastung des Präsidierenden Bundes und des Kassenamtes.</li>
      <li>Die Verbandsversammlung beschließt eine Geschäftsordnung.</li>
      <li>Die Verbandsversammlung wählt für das nächste Geschäftsjahr zwei Kassenprüfer, die nicht dem Präsidierenden Bund oder dem des Kassenamtes angehören dürfen. Sie haben der nächsten Verbandsversammlung einen schriftlichen Bericht für das vorausgegangene Geschäftsjahr vorzulegen.</li>
    </ol>

    <p>§5.4 Stimmrecht auf der Verbandsversammlung</p>
    <ol>
      <li>Zur VV entsendet jeder Bund zwei stimmberechtigte Vertreter, und zwar einen für die Aktivitas und einen für den Alt-Herren-Verband.</li>
      <li>Jeder stimmberechtigte Vertreter hat eine Stimme.</li>
      <li>Bünde ohne Aktivitas oder ohne Altherrenverband haben nur eine Stimme.</li>
      <li>In Angelegenheiten, die lediglich die Alt-Herren-Verbände oder lediglich die Aktivitates betreffen, sind nur die Alt-Herren-Verbände bzw. nur die Aktivitates stimmberechtigt. Die Entscheidung darüber, ob eine solche Angelegenheit vorliegt, trifft von Fall zu Fall die Verbandsversammlung.</li>
      <li>Die Verbandsversammlung ist beschlussfähig, wenn mindestens die Hälfte der Stimmberechtigten vertreten ist.</li>
    </ol>

    <p>§5.5 Anträge zur Verbandsversammlung</p>
    <ol>
      <li>Antragsberechtigt sind jede Aktivitas und jeder Alt-Herren-Verband, der Ständige Ausschuss, das Kassenamt und der Alt-Herren-Rat.</li>
      <li>Alle Anträge sind schriftlich mit Begründung einzureichen. Sie müssen zwei Monate vor der VV dem Präsidium zu übermitteln werden. Später eingegangene Anträge können von der VV zugelassen werden.</li>
      <li>Anträge zu Satzungsänderungen bedürfen zweier Lesungen. Wird ein Antrag zur Satzungsänderung angenommen, kann bis auf weiteres im Sinne dieser Änderung verfahren werden. Die zweite endgültige Lesung muss auf der nächsten ordentlichen VV stattfinden.</li>
      <li>Ein Antrag ist angenommen, wenn für ihn mindestens eine Stimme mehr als dagegen abgegeben worden ist.</li>
      <li>Eine Zweidrittelmehrheit der abgegebenen Stimmen ist erforderlich für
        <p className='mb-1'>a. die Aufnahme eines Mitgliedes</p>
        <p className='mb-1'>b. den Ausschluss eines Mitgliedes</p>
        <p className='mb-1'>c. Satzungsänderungen</p>
        <p className='mb-1'>d. die Auflösung des Verbandes</p>
      </li>
    </ol>

    <p>§5.6 Schriftliche Abstimmung im Ausnahmefall</p>
    <ol>
      <li>In Ausnahmefällen, über deren Vorliegen das Präsidium entscheidet, kann - statt auf einer Verbandsversammlung - schriftlich abgestimmt werden. Das gilt nicht in den Fällen der Abstimmung über des Ausschlusses eines Mitgliedes, Satzungsänderungen und der Auflösung des Verbandes. (§E5 Nr. 5 Abs. b, c und d).</li>
    </ol>

    <p>§5.7 Wahl des Präsidierenden Bundes</p>
    <ol>
      <li>Der Präsidierende Bund ist der von der Verbandsversammlung für das folgende Geschäftsjahr gewählte und dazu bereite Bund. Die Bereitschaft darf nur aus zwingenden, vor der Verbandsversammlung darzulegenden Gründen versagt werden. Eine Wiederwahl kann ohne Begründung abgelehnt werden.</li>
      <li>Stimmenthaltungen sind nicht zulässig.</li>
      <li>Der Präsidierende Bund bleibt bis zur Amtsübergabe im Amt.</li>
    </ol>

    <p>§5.8 Aufgaben des Präsidierenden Bundes</p>
    <ol>
      <li>Der Präsidierende Bund führt nach den Satzungen, den Entscheidungen der Verbandsversammlung, der Geschäftsordnung und nach pflichtgemäßem Ermessen die Geschäfte des Verbandes.</li>
      <li>Er hat den Verband gegenüber seinen Mitgliedsbünden zu vertreten,</li>
      <li>den Verband nach Außen hin zu repräsentieren,</li>
      <li>die Verbandsversammlungen vorzubereiten, einzuberufen und die damit verbundenen Veranstaltungen mit Ordnungsgewalt zu leiten,</li>
      <li>den Haushaltsplan-Entwurf der Verbandsversammlung zur Beschlussfassung vorzulegen,</li>
      <li>auf Schlichtung von Streitigkeiten zwischen den Mitgliedsbünden hinzuwirken.</li>
    </ol>

    <p>§5.9 Das Präsidium des Marburger Konventes</p>
    <ol>
      <li>Die Wahrung der dem Präsidierenden Bund unmittelbar obliegenden Aufgaben erfolgt durch das Präsidium.</li>
      <li>Das Präsidium besteht aus dem Sprecher und zwei Stellvertretern.</li>
      <li>Das Präsidium wird durch den Präsidierenden Bund bestellt.</li>
    </ol>

    <p>§5.10 Der Ständige Ausschuss (STA)</p>
    <ol>
      <li>Der Ständige Ausschuss besteht aus fünf Personen aus mindestens vier Mitgliedsbünden; davon müssen wenigstens zwei Alte Herren und wenigstens zwei Aktive sein.</li>
      <li>Die Mitglieder des Ständigen Ausschusses werden für die Dauer von zwei Geschäftsjahren von der Verbandsversammlung gewählt. Wiederwahl ist zulässig. Scheidet während der Amtszeit des Ständigen Ausschusses ein Mitglied aus, berufen die restlichen Mitglieder einen Nachfolger, und zwar nach Abstimmung mit dem Präsidierenden Bund.</li>
      <li>Der Ständige Ausschuss gibt Anregungen, erarbeitet Stellungnahmen und unterstützt den Präsidierenden Bund. Er kann Informationen einholen und daraufhin Empfehlungen an die Mitgliedsbünde geben.</li>
      <li>Der Ständige Ausschuss ist der Verbandsversammlung verantwortlich. Er erstattet in jeder Verbandsversammlung Bericht.</li>
    </ol>

    <p>§5.11 Das Kassenamt</p>
    <ol>
      <li>Das Kassenamt besteht aus dem Leiter und seinem Vertreter.</li>
      <li>Der Leiter und der Vertreter werden von der Verbandsversammlung für zwei Geschäftsjahre gewählt. Wiederwahl ist zulässig. Bei vorzeitigem Ausscheiden eines Mitgliedes des Kassenamtes bestellt für dessen noch laufende Amtszeit der Präsidierende Bund einen Ersatzmann.</li>
      <li>Das Kassenamt legt der ordentlichen Verbandsversammlung jedes Jahr einen Jahresbericht für das vergangene Geschäftsjahr vor.</li>
      <li>Das Kassenamt hat nach näherer Regelung in der Geschäftsordnung die gesamte Kassen- und Finanzverwaltung zu erledigen. Es ist auskunftspflichtig gegenüber der Verbandsversammlung und dem Präsidierenden Bund.</li>
      <li>Das Kassenamt stellt den Haushaltsplan-Entwurf auf und legt ihn dem Präsidierenden Bund vor. Der Präsidierende Bund kann den Gesamtansatz der Ausgaben nur mit Zustimmung des Kassenamtes erhöhen.</li>
      <li>Das Kassenamt legt der ordentlichen Verbandsversammlung jedes Jahr einen Jahresbericht für das vergangene Geschäftsjahr vor.</li>
    </ol>

    <p>§5.12 Der Alt-Herren-Rat</p>
    <ol>
      <li>Es wird ein Alt-Herren-Rat gebildet. Ihm gehören die Vorsitzenden der Alt-Herren-Verbände oder deren Bevollmächtigte an.</li>
      <li>Die Mitglieder des Alt-Herren-Rates wählen einen Sprecher aus ihrer Mitte.</li>
      <li>Der Alt-Herren-Rat unterstützt die Arbeit des Verbandes und der Bünde.</li>
    </ol>

    <p>§5.13 Sonstige Ämter</p>
    <ol>
      <li>Die Verbandsversammlung kann für einzelne Verbandsaufgaben Ämter einrichten oder Beauftrage bestellen.</li>
      <li>Diese arbeiten mit dem Präsidierenden Bund zusammen, sind jedoch unmittelbar der Verbandsversammlung verantwortlich.</li>
    </ol>

    <h2 className="mt-5">6 Allgemeines</h2>

    <p>§6.1 Das Geschäftsjahr läuft jeweils vom 01. November bis zum 31. Oktober.</p>

    <p>Diese Satzung ist durch die Verbandsversammlung mit den von ihr beschlossenen Änderungen am 18. Mai 2013 in Hann. Münden angenommen worden.</p>

    <p>Stand: 18.5.2013</p>
  </Layout >
)

export default StatuePage 